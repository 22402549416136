<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-content class="md-elevation-5">
        <div class="md-display-1">Are you tired of feeling overwhelmed by your to-do list? Do you struggle to make progress towards your goals because you can't prioritize effectively? Prioritizy is here to help.<br><br>Our innovative application measures the impact of your tasks on your goals, making it easier for you to prioritize like a pro. With Prioritizy, you can streamline your productivity and take control of your day.<br><br>Prioritizy is a powerful application that helps you prioritize like a boss. Say goodbye to the chaos of endless to-do lists and hello to a more productive you. Get started with Prioritizy today and crush your goals like never before.</div>
        <div class="md-display-3"><md-icon>sentiment_very_satisfied</md-icon></div>
        <div class="md-display-1">Prioritizy - Prioritizing made easy</div>
      </md-content>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.md-content {
    margin: 2em;
    padding: 1em;
  }
</style>

<script>
export default {
  name: 'Home'
}
</script>
